import React, { useState } from "react"
import tawas from "../../../static/tawas/tawa-kepler.json"
import corato from "../../../static/corato/corato-kepler.json"

const KeplerDropdown = ({ changeSelect }) => {
    const options = [
        { label: "Select area", value:""},
        { label: "East-Tawas", value: tawas },
        { label: "Corato", value: corato }
    ]
    const [selectedOption, setSelectedOption] = useState(options[0].value)
    const loseFocus = () => {
        document.getElementById("lose-focus").blur();
    }
    return (
        <div className="kepler">
            <select id="lose-focus" className="selectarea"
                value={selectedOption}
                onChange={e => {
                    setSelectedOption(e.target.value)
                    changeSelect(e.target.value)
                }}
                onBlur={e => {}}
            >
                {options.map((o) => (   
                    <option onClick = {() => loseFocus()} key={o.label} value={JSON.stringify(o.value)}>
                        {o.label}
                    </option>
                ))}
            </select>
        </div>
    )
}
export default KeplerDropdown