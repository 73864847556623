import React, { useEffect } from 'react'
import KeplerGlSchema from 'kepler.gl/schemas';
import {AutoSizer} from 'react-virtualized';
import { useDispatch } from "react-redux"
import { addDataToMap } from "kepler.gl/actions"
import KeplerGl from "kepler.gl"


const KeplerMap = (addData) => {
    const dispatch = useDispatch()
    useEffect(() => {
        const mapToLoad = KeplerGlSchema.load(addData.addData.datasets, addData.addData.config);
        dispatch(
            addDataToMap(mapToLoad)
        )
    })

    //https://github.com/keplergl/kepler.gl/blob/master/src/styles/base.js
    const customTheme = {
        sidePanelBg: "#29323c",
        titleTextColor: "#fff",
        sidePanelHeaderBg: "#29323c",
        subtextColorActive: "#fff"
    }
    return (
       <>
        {typeof window !== "undefined" ? (
            <AutoSizer>
            {({height, width}) => (
                <KeplerGl
                    width={width}
                    height={height}
                    id="areas"
                    theme={customTheme}
                    mapboxApiAccessToken={process.env.MAPBOX_API_TOKEN}
                />
            )}
            </AutoSizer>
            ) : null}
        </>
    )
}

export default KeplerMap