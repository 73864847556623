import React, {useState} from "react"
import Layout from "../components/Layout"
import KeplerDropdown from "../components/Insights/KeplerDropdown"
import KeplerMap from "../components/Insights/KeplerMap"


const InsightsPage = () => {
  const [area, setArea] = useState("")
  return (
    <Layout className="insights" titlePage="Insights">
      <div className="insights-kepler">
        <KeplerDropdown changeSelect={e => setArea(JSON.parse(e))} />
        <KeplerMap addData={area} />
      </div>
    </Layout>
  )
}
export default InsightsPage